import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import Swal from "sweetalert2";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./PracePage.css";
import { checkPermissions2, checkPermissions3, getuser3 } from "./Permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faTimes, faCogs, faFilePdf } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import {
  faCalendarAlt,
  faCalendarMinus,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons"; // Import ikon
import { resizeModalToScreen } from "./Autosize.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
const PracePage = ({ loggedUser, onClose }) => {
  const [szukaj, szukajChange] = useState(""); // Nowe pole wyszukiwania
  const [prace, setPrace] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [klienci, setKlienci] = useState([]);
  const [filteredKlienci, setFilteredKlienci] = useState([]);
  const [uzytkownicy, setUzytkownicy] = useState([]);
  const [filterType, setFilterType] = useState("C");
  const [filterUserOnly, setFilterUserOnly] = useState(
    () => !checkPermissions3(2)
  );
  const uzyt_id = getuser3();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;
  const [isFullPeriod, setIsFullPeriod] = useState(false);
  const getDateBefore180Days = () => {
    const today = new Date();
    today.setDate(today.getDate() - 180);
    return today.toISOString().split("T")[0]; // Zwracamy w formacie yyyy-mm-dd
  };

  const getDateAfter90Days = () => {
    const today = new Date();
    today.setDate(today.getDate() + 90);
    return today.toISOString().split("T")[0]; // Zwracamy w formacie yyyy-mm-dd
  };

  const handleszukaj = (e) => {
    szukajChange(e.target.value.toLowerCase());
    fetchPrace();
  };

  const [formData, setFormData] = useState({
    klient_id: "",
    klient_nazwa: "",
    klient_szukaj: "",
    uzytkownik_id: loggedUser.email,
    data: new Date().toISOString().split("T")[0],
    filter_data_od: getDateBefore180Days(), // Domyślna wartość sprzed 180 dni
    //data_od: getDateBefore180Days(), // Domyślna wartość sprzed 180 dni
    data_od: new Date().toISOString().split("T")[0], // Domyślna wartość sprzed 180 dni
    data_do: new Date().toISOString().split("T")[0], // Domyślna wartość sprzed 180 dni
    //data_do: getDateAfter90Days(), // Domyślna wartość sprzed 180 dni
    //filter_data_do: new Date().toISOString().split("T")[0], // Domyślna dzisiejsza data
    filter_data_do: getDateAfter90Days(), // Domyślna dzisiejsza data
    godzina: new Date().getHours() + new Date().getMinutes() / 60,
    ile_czasu: "",
    ile_czasu2: "",
    opis: "",
    opis2: "",
    opis2c: "",
    trwa: "",
    projekt: "",
    projekt2: "",
    uzytkownik2: loggedUser.email,
    datatdo: new Date().toISOString().split("T")[0],
  });
  const [filter_klient_id, setfklien] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fetchPrace = useCallback(async () => {
    try {
      const userFilter = filterUserOnly
        ? `&uzytkownik=${loggedUser.email}`
        : "";

      const dateFilter =
        formData.filter_data_od && formData.filter_data_do
          ? `&data_odd=${formData.filter_data_od}&data_doo=${formData.filter_data_do}`
          : "";

      const klientFilter = filter_klient_id
        ? `&klient_id=${filter_klient_id}`
        : "";
      // alert("klient filtr: " + klientFilter);
      // alert("filter_klient_id: " + filter_klient_id);

      const szukajFilter = szukaj ? `&szukaj=${szukaj}` : "";

      const response = await fetch(
        `/api/prace/?co=${filterType}&skip=${
          (currentPage - 1) * recordsPerPage
        }&limit=${recordsPerPage}${userFilter}${dateFilter}${klientFilter}${szukajFilter}`,
        {
          method: "GET",
          cache: "no-store",
        }
      );
      // alert(klientFilter);

      const data = await response.json();
      setPrace(data.prace);
      setTotalRecords(data.total);
      // alert(data.total);
    } catch (error) {
      alert("Brak");
      console.error("Error fetching data:", error);
    }
  }, [
    filterType,
    currentPage,
    recordsPerPage,
    filterUserOnly,
    loggedUser.email,
    filter_klient_id,
    formData.filter_data_od,
    formData.filter_data_do,
    szukaj,
  ]);
  const filtrPrace = async () => {
    fetchPrace();
  };

  const fetchKlienci = async () => {
    try {
      localStorage.setItem("lastActivity", new Date().toISOString());
      const response = await fetch("/api/klienci/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setKlienci(data);
      setFilteredKlienci(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const fetchUzytkownicy = async () => {
    try {
      const response = await fetch("/api/uzytkownicy/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setUzytkownicy(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchPrace();
    fetchKlienci();
    fetchUzytkownicy();
    if (isModalOpen) {
      // alert("aaa");
      resizeModalToScreen(); // Dopasuj modal do rozdzielczości przy otwieraniu
      window.addEventListener("resize", resizeModalToScreen); // Dodaj listener na zmianę rozmiaru okna
      return () => {
        window.removeEventListener("resize", resizeModalToScreen); // Usuń listener po zamknięciu modala
      };
    }
  }, [filterType, currentPage, fetchPrace, isModalOpen]);

  const setCurrentMonth = () => {
    setFilterType("M");
    setCurrentPage(1);
    setIsFullPeriod(false);
  };

  const setPreviousMonth = () => {
    setFilterType("P");
    setCurrentPage(1);
    setIsFullPeriod(false);
  };

  const setFullPeriod = () => {
    setFilterType("C");
    setCurrentPage(1);
    setIsFullPeriod(true);
  };

  const setTrwaja = () => {
    setFilterType("T");
    setCurrentPage(1);
    setIsFullPeriod(true);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setFormData({ ...formData, klient_szukaj: searchTerm });

    const filtered = klienci.filter((klient) => {
      return (
        klient.nazwa.toLowerCase().includes(searchTerm) ||
        klient.nip.toLowerCase().includes(searchTerm) ||
        klient.email.toLowerCase().includes(searchTerm) ||
        klient.telefon.toLowerCase().includes(searchTerm)
      );
    });
    setFilteredKlienci(filtered);
  };

  const handleEditPraca = (praca) => {
    if (checkPermissions2(1)) {
      setIsEditing(true);
      setFormData({
        id: praca.id,
        klient_id: praca.klient_id ? praca.klient_id.toString() : "",
        klient_nazwa: praca.klient_nazwa || "",
        uzytkownik_id: praca.uzytkownik_id,
        data: praca.data.split("T")[0],
        data_od: praca.data_od.split("T")[0],
        data_do: praca.data_do.split("T")[0],
        godzina: praca.godzina,
        ile_czasu: praca.ile_czasu,
        ile_czasu2: praca.ile_czasu2,
        opis: praca.opis || "",
        opis2: praca.opis2 || "",
        opis2c: praca.opis2c || "",
        projekt: praca.projekt || "",
        projekt2: praca.projekt2 || "",
        trwa: praca.trwa === "X" ? "X" : " ", // Przypisujemy "X" lub pusty strin
        datatdo: praca.datatdo ? praca.datatdo : Date(),
        uzytkownik2: praca.uzytkownik2,
      });

      setIsModalOpen(true);
    }
  };

  const generatePdf = (prace) => {
    const doc = new jsPDF();

    // Ustawienie czcionki na niestandardową z obsługą polskich znaków
    doc.setFont("Roboto-Regular", "normal");
    doc.setFontSize(12);

    // Nagłówki tabeli
    const headers = [
      { title: "Klient", dataKey: "klient_nazwa" },
      { title: "Użytkownik", dataKey: "uzytkownik_id" },
      { title: "Data od", dataKey: "data_od" },
      { title: "Data do", dataKey: "data_do" },
      { title: "Ilość czasu", dataKey: "czas" },
      { title: "Opis", dataKey: "opis" },
      { title: "Projekt", dataKey: "projekt" },
    ];

    // Przygotowanie danych do tabeli
    const data = prace.map((praca) => ({
      klient_nazwa: praca.klient_nazwa || "",
      uzytkownik_id: praca.uzytkownik_id || "",
      data_od: praca.data_od ? praca.data_od.split("T")[0] : "",
      data_do: praca.data_do ? praca.data_do.split("T")[0] : "",
      czas:
        checkPermissions3(12) && praca.opis2c === "X"
          ? String(praca.ile_czasu || "")
          : String(praca.ile_czasu2 || ""),
      opis:
        checkPermissions3(12) && praca.opis2c === "X"
          ? praca.opis || ""
          : praca.opis2 || "",
      projekt:
        checkPermissions3(12) && praca.opis2c === "X"
          ? praca.projekt || ""
          : praca.projekt2 || "",
    }));

    // Tytuł i podtytuł
    const title = "Kancelaria Radcy Prawnego Przemysław Budzyński";
    const subtitle = "Zestawienie prac";
    doc.setFontSize(14);
    doc.text(title, 10, 10);
    doc.setFontSize(12);
    doc.text(subtitle, 10, 20);
    doc.setFont("Roboto-Regular", "normal");

    // Generowanie tabeli z użyciem autotable
    doc.autoTable({
      head: [headers.map((header) => header.title)], // Tylko tytuły kolumn
      body: data.map((row) => Object.values(row)), // Mapowanie wartości obiektów do wierszy
      startY: 30,
      styles: { font: "Roboto-Regular" },
      headStyles: { fillColor: [41, 128, 185] },
      theme: "grid",
    });

    // Zapisz PDF
    doc.save("prace.pdf");
  };

  const handleAddPraca = () => {
    if (checkPermissions2(1)) {
      setIsEditing(false);
      const currentHour = new Date().getHours() + new Date().getMinutes() / 60;
      setFormData({
        klient_id: "",
        klient_nazwa: "",
        uzytkownik_id: loggedUser.email,
        data: new Date().toISOString().split("T")[0],
        data_od: new Date().toISOString().split("T")[0],
        data_do: new Date().toISOString().split("T")[0],
        godzina: currentHour,
        ile_czasu2: "",
        ile_czasu: "",
        projekt: "",
        projekt2: "",
        trwa: "",
        opis: "",
        opis2: "",
        opis2c: "",
        uzytkownik2: "",
        datatdo: new Date().toISOString().split("T")[0],
      });
      setIsModalOpen(true);
    }
  };

  const handleDeletePraca = async (id) => {
    if (checkPermissions2(1)) {
      Swal.fire({
        title: "Czy na pewno chcesz usunąć pracę?",
        text: "Tego działania nie można cofnąć!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`/api/prace/${id}?uzyt_id=${uzyt_id}`, { method: "DELETE" })
            .then((response) => {
              if (response.ok) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Praca została usunięta!",
                });
                fetchPrace();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Błąd podczas usuwania pracy",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Wystąpił problem podczas usuwania pracy.",
              });
            });
        }
      });
    }
  };

  const handleUpdatePraca = async (e) => {
    e.preventDefault();

    // Tworzymy kopię formData, żeby pracować na niej przed wysłaniem
    const updatedFormData = { ...formData };

    // Sprawdź i uzupełnij domyślne wartości
    if (updatedFormData.opis2c !== "X") {
      updatedFormData.opis = updatedFormData.opis2;
      updatedFormData.ile_czasu = updatedFormData.ile_czasu2;
      updatedFormData.projekt = updatedFormData.projekt2;
    }

    try {
      const response = await fetch(
        `/api/prace/${updatedFormData.id}?uzyt_id=${uzyt_id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedFormData), // Przekazujemy dane z uzupełnionymi wartościami
        }
      );
      // alert(JSON.stringify(updatedFormData));

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Praca została zaktualizowana!",
        });
        fetchPrace(); // Odśwież listę prac
        setIsModalOpen(false); // Zamknij modal
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Błąd podczas aktualizacji pracy " +
            JSON.stringify(updatedFormData),
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddNewPraca = async (e) => {
    e.preventDefault();

    // Tworzymy kopię formData, żeby pracować na niej przed wysłaniem
    const newFormData = { ...formData };

    // Sprawdź i uzupełnij domyślne wartości
    if (!newFormData.opis) {
      newFormData.opis = newFormData.opis2;
    }

    if (!newFormData.ile_czasu) {
      newFormData.ile_czasu = newFormData.ile_czasu2;
    }

    if (!newFormData.trwa) {
      newFormData.trwa = " ";
    }

    if (!newFormData.projekt) {
      newFormData.projekt = newFormData.projekt2;
    }

    // Upewniamy się, że formularz został poprawnie uzupełniony i wysyłamy dane
    try {
      const response = await fetch(`/api/prace/?uzyt_id=${uzyt_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newFormData), // Przekazujemy dane z uzupełnionymi wartościami
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Praca została dodana!",
        });
        fetchPrace(); // Odśwież listę prac
        setIsModalOpen(false); // Zamknij modal
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Błąd podczas dodawania pracy " + JSON.stringify(newFormData),
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const validateForm = () => {
    const dateDo = formData.data_do;
    const dateOd = formData.data_od;
    if (new Date(dateDo) < new Date(dateOd)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Data do musi być większa lub równa dacie od",
      });
      return false; // Zatrzymuje wysłanie formularza
    }
    if (new Date(dateDo).getMonth() !== new Date(dateOd).getMonth()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Data od i data do muszą być w tym samym miesiącu",
      });
      return;
    }

    let regex;
    if (formData.trwa === "X") {
      regex = /^(0|\d{1,4})(\.\d)?$/; // Akceptuje "0", liczbę całkowitą do 4 cyfr lub liczbę z jedną cyfrą po przecinku
    } else {
      regex = /^(?!0$)\d{1,4}(\.\d)?$/; // Akceptuje liczby całkowite do 4 cyfr lub liczby z jedną cyfrą po przecinku, ale nie akceptuje samego "0"
    }

    if (regex.test(formData.ile_czasu2)) {
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Wpis godziny formacie 9999.9 ",
      });
      return false; // Zatrzymuje wysłanie formularza
    }
    if (formData.opis2c === "X") {
      if (regex.test(formData.ile_czasu)) {
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Wpis godziny2 formacie 9999.9 ",
        });
        return false; // Zatrzymuje wysłanie formularza
      }
    }

    // Możesz dodać więcej warunków walidacyjnych
    // np. sprawdzenie, czy inne pola są poprawnie wypełnione

    return true; // Kontrola zakończona powodzeniem
  };

  // Funkcja obsługująca `onSubmit`
  const handleSubmit = (e) => {
    e.preventDefault(); // Zatrzymuje domyślne wysłanie formularza

    if (!validateForm()) {
      return; // Jeśli walidacja nie przeszła, formularz nie zostanie wysłany
    }

    // Jeśli walidacja przeszła, uruchamiamy odpowiednią funkcję
    isEditing ? handleUpdatePraca(e) : handleAddNewPraca(e);
  };

  return (
    <div className="prace-page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>
          Lista prac {totalRecords} strona {currentPage}{" "}
        </h2>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      ></div>

      <div className="filter-buttons">
        <button className="add-praca" onClick={setCurrentMonth}>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{ marginRight: "8px" }}
          />
          Bieżący miesiąc
        </button>
        <button className="add-praca" onClick={setPreviousMonth}>
          <FontAwesomeIcon
            icon={faCalendarMinus}
            style={{ marginRight: "8px" }}
          />
          Poprzedni miesiąc
        </button>
        <button className="add-praca" onClick={setFullPeriod}>
          <FontAwesomeIcon
            icon={faCalendarCheck}
            style={{ marginRight: "8px" }}
          />
          Cały okres
        </button>
        {checkPermissions3(13) && (
          <button className="add-praca" onClick={setTrwaja}>
            <FontAwesomeIcon icon={faCogs} style={{ marginRight: "8px" }} />
            Prace w toku
          </button>
        )}
        {checkPermissions3(16) && (
          <button className="add-praca" onClick={() => generatePdf(prace)}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: "8px" }} />
            Pdf
          </button>
        )}

        {checkPermissions3(2) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <input
              type="checkbox"
              checked={filterUserOnly}
              onChange={(e) => setFilterUserOnly(e.target.checked)}
              style={{ width: "30px", height: "30px", marginRight: "15px" }} // Powiększenie przycisku i dodanie odstępu
            />
            <label style={{ fontSize: "20px", marginRight: "15px" }}>
              Tylko moje prace
            </label>
          </div>
        )}
        <label>
          Klient {filter_klient_id}:
          <select
            name="filter_klient_id3"
            value={filter_klient_id}
            onChange={(e) => {
              // Zaktualizowanie stanu formData z wartością klienta
              // setFormData({ ...formData, filter_klient_id: e.target.value });
              // setfklien(e.target.value);
              // Resetowanie filtra, jeśli klient nie jest wybrany
              if (e.target.value === "") {
                // Wywołanie filtrów bez klienta
                setfklien("");
                //filtrPrace({ ...formData, filter_klient_id: "" });
              } else {
                // Przeprowadzenie filtracji z wybranym klientem
                setfklien(e.target.value);
                //filtrPrace({ ...formData, filter_klient_id: e.target.value });
              }
              fetchPrace();
            }}
          >
            <option value="">Wybierz klienta</option>
            {filteredKlienci.map((klient) => (
              <option key={klient.id} value={klient.id}>
                {klient.nazwa}
              </option>
            ))}
          </select>
        </label>

        {/* Pole wyszukiwania */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Wyszukaj"
            value={szukaj}
            onChange={handleszukaj}
          />
        </div>
      </div>

      {isFullPeriod && (
        <div className="additional-filters">
          <label>
            Data od:
            <input
              type="date"
              name="filter_data_od"
              value={formData.filter_data_od}
              onChange={(e) => {
                setFormData({ ...formData, filter_data_od: e.target.value });
                filtrPrace();
              }}
            />
          </label>
          <label>
            Data do:
            <input
              type="date"
              name="filter_data_do"
              value={formData.filter_data_do}
              onChange={(e) => {
                setFormData({ ...formData, filter_data_do: e.target.value });
                filtrPrace();
              }}
            />
          </label>
        </div>
      )}

      <button className="add-praca" onClick={handleAddPraca}>
        <i className="fas fa-plus"></i> Dodaj pracę
      </button>
      {/* <button className="add-praca" onClick={filtrPrace}>
        <i className="fas fa-plus"></i> Filtruj
      </button> */}
      <table>
        <thead>
          <tr>
            <th>Klient</th>
            <th>Użytkownik</th>
            <th>Data od</th>
            <th>Data do</th>
            <th>Ilość czasu</th>
            <th>Opis</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {prace.map((praca) => (
            <tr className="trglowna" key={praca.id}>
              <td>{praca.klient_nazwa}</td>
              <td>{praca.uzytkownik_id}</td>
              <td>{praca.data_od.split("T")[0]}</td>
              <td>{praca.data_do.split("T")[0]}</td>
              <td style={{ textAlign: "right" }}>{praca.ile_czasu2}</td>
              <td>{praca.opis2}</td>
              <td className="action-buttons">
                <button className="edit" onClick={() => handleEditPraca(praca)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button
                  className="delete"
                  onClick={() => handleDeletePraca(praca.id)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {/* Przycisk do pierwszej strony */}
        {currentPage > 1 && <button onClick={() => paginate(1)}>1</button>}

        {/* Elipsa, jeśli jesteśmy daleko od początku */}
        {currentPage > 4 && <span>...</span>}

        {(() => {
          const totalPages = Math.ceil(totalRecords / recordsPerPage);
          const maxButtons = 5; // Maksymalna liczba przycisków w środku
          let startPage = Math.max(2, currentPage - Math.floor(maxButtons / 2));
          let endPage = Math.min(totalPages - 1, startPage + maxButtons - 1);

          // Korekta, gdy zabraknie przycisków z prawej strony
          if (endPage - startPage < maxButtons - 1) {
            startPage = Math.max(2, endPage - maxButtons + 1);
          }

          const buttons = [];
          for (let page = startPage; page <= endPage; page++) {
            buttons.push(
              <button
                key={page}
                className={page === currentPage ? "active" : ""}
                onClick={() => paginate(page)}
              >
                {page}
              </button>
            );
          }
          return buttons;
        })()}

        {/* Elipsa, jeśli jesteśmy daleko od końca */}
        {currentPage < Math.ceil(totalRecords / recordsPerPage) - 3 && (
          <span>...</span>
        )}

        {/* Przycisk do ostatniej strony */}
        {currentPage < Math.ceil(totalRecords / recordsPerPage) && (
          <button
            onClick={() => paginate(Math.ceil(totalRecords / recordsPerPage))}
          >
            {Math.ceil(totalRecords / recordsPerPage)}
          </button>
        )}
      </div>

      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>
          {isEditing ? `Edytuj pracę (ID: ${formData.id})` : "Dodaj pracę"}
        </h2>
        <form class="formmodalpraca" onSubmit={handleSubmit}>
          <table>
            <tr>
              <td class="one-third">
                <input
                  type="text"
                  placeholder="Wyszukaj klienta (nazwa, NIP, e-mail, telefon)"
                  value={formData.klient_szukaj}
                  onChange={handleSearchChange}
                />

                <select
                  name="klient_id"
                  value={formData.klient_id}
                  onChange={(e) => {
                    const selectedKlient = klienci.find(
                      (klient) => klient.id === parseInt(e.target.value, 10)
                    );
                    setFormData({
                      ...formData,
                      klient_id: e.target.value,
                      klient_nazwa: selectedKlient ? selectedKlient.nazwa : "",
                    });
                  }}
                  required
                >
                  <option value="">Wybierz klienta</option>
                  {filteredKlienci.map((klient) => (
                    <option key={klient.id} value={klient.id}>
                      {klient.nazwa} - {klient.nip} - {klient.email} -{" "}
                      {klient.telefon}
                    </option>
                  ))}
                </select>
                <select
                  name="uzytkownik_id"
                  value={formData.uzytkownik_id}
                  onChange={(e) =>
                    setFormData({ ...formData, uzytkownik_id: e.target.value })
                  }
                  required
                >
                  {/* Zawsze wyświetlaj zalogowanego użytkownika */}
                  <option value={loggedUser.email}>
                    {loggedUser.imie} {loggedUser.nazwisko} (Zalogowany)
                  </option>

                  {/* Jeśli użytkownik ma odpowiednie uprawnienia (checkPermissions3(2) === true), wyświetl dodatkowe opcje */}
                  {checkPermissions3(2) &&
                    uzytkownicy.map((uzytkownik) => (
                      <option key={uzytkownik.email} value={uzytkownik.email}>
                        {uzytkownik.imie} {uzytkownik.nazwisko}
                      </option>
                    ))}
                </select>
                <input
                  type="date"
                  name="data_od"
                  value={formData.data_od}
                  onChange={(e) => {
                    setFormData({ ...formData, data_od: e.target.value });
                    // const dateOd = e.target.value;
                    // const dateDo = formData.data_do;

                    // // Sprawdzenie, czy data_do jest w tym samym miesiącu co data_od
                    // if (
                    //   dateDo &&
                    //   new Date(dateOd).getMonth() !==
                    //     new Date(dateDo).getMonth()
                    // ) {
                    //   // Swal.fire({
                    //   //   icon: "error",
                    //   //   title: "Error",
                    //   //   text: "Data do dostosowana do miesiąca od",
                    //   // });
                    //   setFormData({ ...formData, data_od: e.target.value });
                    //   setFormData({ ...formData, data_do: e.target.value });
                    //   return;
                    //}

                    // if (dateDo && new Date(dateDo) < new Date(dateOd)) {
                    //   Swal.fire({
                    //     icon: "error",
                    //     title: "Error",
                    //     text: "Data do musi być większa lub równa dacie od",
                    //   });
                    //   return;
                    // }
                    // setFormData({ ...formData, data_od: dateOd });
                  }}
                  required
                />
                <input
                  type="date"
                  name="data_do"
                  value={formData.data_do}
                  onChange={(e) => {
                    setFormData({ ...formData, data_do: e.target.value });
                  }}
                  //   const dateDo = e.target.value;
                  //   const dateOd = formData.data_od;

                  //   if (
                  //     dateOd &&
                  //     new Date(dateDo).getMonth() !==
                  //       new Date(dateOd).getMonth()
                  //   ) {
                  //     Swal.fire({
                  //       icon: "error",
                  //       title: "Error",
                  //       text: "Data od i data do muszą być w tym samym miesiącu",
                  //     });
                  //     return;
                  //   }
                  //   if (dateOd && new Date(dateDo) < new Date(dateOd)) {
                  //     Swal.fire({
                  //       icon: "error",
                  //       title: "Error",
                  //       text: "Data do musi być większa lub równa dacie od",
                  //     });
                  //     return;
                  //   }
                  //   setFormData({ ...formData, data_do: dateDo });
                  // }}
                  required
                />

                <textarea
                  name="opis2"
                  value={formData.opis2}
                  onChange={(e) =>
                    setFormData({ ...formData, opis2: e.target.value })
                  }
                  placeholder="Opis pracy"
                />
                <input
                  type="number"
                  name="ile_czasu2"
                  value={formData.ile_czasu2}
                  onChange={(e) =>
                    setFormData({ ...formData, ile_czasu2: e.target.value })
                  }
                  // onChange={(e) => {
                  //   const value = e.target.value;
                  //   let regex;

                  //   regex = /^(0|\d{1,4})(\.\d)?$/; // Akceptuje "0", liczbę całkowitą do 4 cyfr lub liczbę z jedną cyfrą po przecinku

                  //   if (regex.test(value)) {
                  //     setFormData({ ...formData, ile_czasu2: value });
                  //   } else {
                  //     Swal.fire({
                  //       icon: "error",
                  //       title: "Error",
                  //       text: "Wpis tylko w formacie 9999.9 ",
                  //     });
                  //     return;
                  //   }
                  // }}
                  placeholder="Czas (np. 1.2)"
                  required
                />
                <input
                  type="text"
                  name="projekt2"
                  value={formData.projekt2}
                  onChange={(e) =>
                    setFormData({ ...formData, projekt2: e.target.value })
                  }
                  placeholder="Projekt do grupowania"
                />
                <select
                  name="uzytkownik2"
                  value={formData.uzytkownik2 || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, uzytkownik2: e.target.value })
                  }
                >
                  {/* Default selectable option for "no selection" */}
                  <option value="">Wybierz współautora</option>

                  {/* Render user options */}
                  {uzytkownicy.map((uzytkownik) => (
                    <option key={uzytkownik.email} value={uzytkownik.email}>
                      {uzytkownik.imie} {uzytkownik.nazwisko}
                    </option>
                  ))}
                </select>
              </td>

              {checkPermissions3(12) && (
                <td class="one-third">
                  {checkPermissions3(12) && (
                    <input
                      type="number"
                      name="ile_czasu"
                      value={formData.ile_czasu}
                      onChange={(e) =>
                        setFormData({ ...formData, ile_czasu: e.target.value })
                      }
                      // onChange={(e) => {
                      //   const value = e.target.value;
                      //   let regex;

                      //   regex = /^(0|\d{1,4})(\.\d)?$/; // Akceptuje "0", liczbę całkowitą do 4 cyfr lub liczbę z jedną cyfrą po przecinku

                      //   if (regex.test(value)) {
                      //     setFormData({ ...formData, ile_czasu: value });
                      //   } else {
                      //     Swal.fire({
                      //       icon: "error",
                      //       title: "Error",
                      //       text: "Wpis tylko w formacie 9999.9 ",
                      //     });
                      //     return;
                      //   }
                      // }}
                      placeholder="Czas rozliczeniowy"
                    />
                  )}

                  {checkPermissions3(12) && (
                    <input
                      type="text"
                      name="projekt"
                      value={formData.projekt}
                      onChange={(e) =>
                        setFormData({ ...formData, projekt: e.target.value })
                      }
                      placeholder="Projekt rozliczeniowy"
                    />
                  )}
                  {checkPermissions3(12) && (
                    <textarea
                      name="opis"
                      value={formData.opis}
                      onChange={(e) =>
                        setFormData({ ...formData, opis: e.target.value })
                      }
                      placeholder="Opis rozliczeniowy"
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "18px",
                        display: "inline-flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      Opisy i czas rozliczeniowe 2
                    </label>
                    <input
                      type="checkbox"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "20px",
                      }} // Odstęp między tekstem a checkboxem
                      checked={formData.opis2c === "X"} // Checkbox jest zaznaczony, gdy formData.trwa to "X"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          opis2c: e.target.checked ? "X" : " ", // Zmiana na "X" jeśli zaznaczony, inaczej na " "
                        });
                      }}
                    />
                  </div>
                </td>
              )}
            </tr>
          </table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Praca trwa
            </label>
            <input
              type="checkbox"
              style={{ width: "30px", height: "30px", marginRight: "10px" }} // Odstęp między tekstem a checkboxem
              checked={formData.trwa === "X"} // Checkbox jest zaznaczony, gdy formData.trwa to "X"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  trwa: e.target.checked ? "X" : " ", // Zmiana na "X" jeśli zaznaczony, inaczej na " "
                });
              }}
            />
            {formData.trwa === "X" && (
              <label
                style={{
                  fontSize: "18px",
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                Termin wykonania
              </label>
            )}
            {formData.trwa === "X" && (
              <input
                type="date"
                name="datatdo"
                value={formData.datatdo}
                onChange={(e) =>
                  setFormData({ ...formData, datatdo: e.target.value })
                }
                placeholder="Planowana data wykonania"
                style={{ width: "200px", marginLeft: "10px" }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              type="submit"
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Zapisz
            </button>
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#dc3545",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Anuluj
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PracePage;
